<template>
  <div class="sd-testimonials">
    <el-carousel
      ref="carousel"
      :initial-index="initialTestimonial"
      arrow="never"
      :autoplay="false"
      height="600px"
    >
      <el-carousel-item
        v-for="(testimonial, index) in testimonials"
        :key="index"
        class="testimonial text-center"
      >
        <img

          :src="testimonial.image"
          :alt="testimonial.name"
        >
        <p class="font-21 text-white pb-5">
          {{ testimonial.text }}
        </p>
        <div class="mt-5 text-white font-weight-strong font-15">
          {{ testimonial.name }}
        </div>
        <div class="text-white font-15 mt-1">
          {{ testimonial.business }}
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import kellie from '@/assets/testimonials/kellie.jpg';
import katie from '@/assets/testimonials/katie.jpg';
import ty from '@/assets/testimonials/ty.jpg';

import { watch, computed } from '@vue/composition-api';

export default {
  name: 'SdTestimonials',
  props: {},
  setup(props, context) {
    watch(() => context.root.$store.state.Ui.currentTestimonial, setActiveTestimonial);
    const initialTestimonial = computed(computeInitialTestimonial);
    return {
      testimonials,
      initialTestimonial,
    };

    function setActiveTestimonial(index) {
      context.refs.carousel.setActiveItem(index);
    }

    function computeInitialTestimonial() {
      if (context.root.$route.name === 'app.subscribe') {
        return 2;
      }
      return 0;
    }
  },
};

const testimonials = [
  {
    image: kellie,
    text: '“Showdigs has disrupted and benefitted the industry. Having flexibility in a small business is huge.”',
    name: 'Kellie Tollifson',
    business: 'T-Square Properties',
  },
  {
    image: katie,
    text: '“With Showdigs, I can manage a larger portfolio and provide better customer service to my tenants and clients.”',
    name: 'Katie Hampton',
    business: 'Maple Leaf Property Management',
  },
  {
    image: ty,
    text: '“After using Showdigs exclusively, we quickly noticed that 60% of showings were occurring outside of office hours. Thank you Showdigs!”',
    name: ' Ty Cayce',
    business: 'Urban Key Property Management',
  },
];
</script>

<style lang="scss" scoped>
.sd-testimonials {
  margin-top: 15vh;
  .testimonial {
    img {
      border-radius: 50%;
      border: 5px solid $white;
      width: 103px;
      margin-bottom: 3.5rem;
      background-color: $white;
    }
  }
}

</style>
