<template>
  <div
    class="layout"
    :class="$viewport.gt.md ? 'flex-row' : 'd-flex'"
  >
    <div
      class="col-12 col-md-3"
      :class="$viewport.gt.md ? 'bg-primary' : 'text-center'"
    >
      <a
        class="logo"
        href="/"
      >
        <img
          :class="$viewport.gt.md ? 'p-4': 'p-5'"
          :src="$viewport.gt.md ? logoWhite : logo"
          alt="logo"
        >
      </a>
      <SdTestimonials
        v-if="$viewport.gt.md"
      />
      <HelpCenter />
    </div>
    <div class="col-12 col-md-9 d-flex justify-content-center flex-grow-1">
      <router-view class="auth-content" />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import SdTestimonials from '@/components/common/SdTestimonials';
import logo from '@/assets/logo.svg';
import logoWhite from '@/assets/logo-white.svg';
import HelpCenter from '@/components/common/HelpCenter';

export default {
  components: { HelpCenter, SdTestimonials },
  setup() {
    const currentTestimonial = ref(1);
    return {
      logo,
      logoWhite,
      currentTestimonial,
    };
  },
};
</script>

<style lang="scss" scoped>
.auth-content{
  width: 474px;
}
</style>
